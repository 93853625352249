import classNames from 'classnames'
import React from 'react'
import { TooltipArg } from 'react-popper-tooltip/dist/types'
import TooltipTrigger from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'
export interface TooltipProps {
  children: React.ReactNode
  text: string
}

const Tooltip: React.FC<TooltipProps> = ({ children, text }) => {
  const TooltipInfo: React.FC<TooltipArg> = ({
    getTooltipProps,
    getArrowProps,
    tooltipRef,
    arrowRef,
    placement,
  }) => (
    <div
      {...getTooltipProps({
        ref: tooltipRef,
        className: classNames('tooltip-container'),
      })}
    >
      <div
        {...getArrowProps({
          ref: arrowRef,
          'data-placement': placement,
          className: 'tooltip-arrow',
        })}
      />
      <div className="tooltip-body">{text}</div>
    </div>
  )
  return (
    <TooltipTrigger placement="bottom" trigger="hover" tooltip={TooltipInfo}>
      {({ getTriggerProps, triggerRef }) => (
        <div
          data-testid={'tooltip-trigger'}
          {...getTriggerProps({
            ref: triggerRef,
            className: 'trigger',
          })}
        >
          {children}
        </div>
      )}
    </TooltipTrigger>
  )
}

export default Tooltip
