import objectPath from 'object-path'
import { FETCH } from 'actions/constants'
import {
  apiFetchError,
  apiFetchReceive,
  apiFetchRequest,
} from 'actions/apiFetch'

/* Middleware factory */
export default (
  btoa,
  baseUrl,
  apiKey = null,
  config = {},
) => store => next => action => {
  if (action && action.type === FETCH) {
    store.dispatch(apiFetchRequest())

    return (async () => {
      const { path, options } = action
      let auth

      // Optionally add Authorization header. Specify in config where to find
      // username/password in state.
      if (config.authPasswordPath && config.authUsernamePath) {
        const state = store.getState()
        const password = objectPath.get(state, config.authPasswordPath)
        const username = objectPath.get(state, config.authUsernamePath)
        auth = username && password && btoa(`${username}:${password}`)
      }

      const cleanBaseUrl = baseUrl.replace(/\/$/, '') // Strip trailing slash
      const cleanPath = path.replace(/^\//, '') // Strip leading slash

      const optionsWithDefaults = Object.assign({}, options, {
        headers: {
          Accept: 'application/json',
          Authorization: auth && `Basic ${auth}`,
          'Content-Type': 'application/json',
          'X-Api-Key': apiKey,
        },
      })

      if (config.debug) {
        optionsWithDefaults.headers['X-Debug'] = '1'
      }

      const response = await fetch(
        `${cleanBaseUrl}/${cleanPath}`,
        optionsWithDefaults,
      )

      if (!response.ok) {
        store.dispatch(apiFetchError(response))
      }

      // Why are we using a timeout here?
      //
      // The apiFetchRequest and apiFetchReceive actions help us keep track of
      // currently running requests (see fetchCounterReducer). This is a great
      // way to show a loading spinner while requests are happening. However,
      // the loading spinner will flash on and off when doing subsequent
      // requests if we don't 'smooth out' the receiving/requesting. We need the
      // previous RECEIVE to dispatch only after the next REQUEST has been
      // dispatched.
      setTimeout(() => store.dispatch(apiFetchReceive()), 100)

      return response
    })()
  }

  return next(action)
}
