import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons'

import { Badge, Headshot } from '../../atoms'
import styles from './listItem.module.scss'
import { Item } from 'types'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'

export interface ListItemProps<T extends Item> {
  className?: string
  onClick?(event: React.MouseEvent): void
  listItem: T
  meetYourRep?: boolean
  withBadge?: {
    text: string
    variant: string
  }
}

const ListItem = <T extends Item>({
  className,
  listItem,
  meetYourRep,
  onClick,
  withBadge,
}: ListItemProps<T>) => {
  const linkTarget = listItem.linkTarget || '' // fix?

  const elType = linkTarget ? Link : 'button'

  return React.createElement(
    elType,
    {
      className: classNames(
        styles.searchResultContainer,
        !onClick && styles.noHover,
        className,
        meetYourRep && styles.meetYourRepListItem,
      ),
      id: listItem.id,
      onClick: (event: React.MouseEvent) => onClick && onClick(event),
      to: linkTarget,
    },
    <>
      <div>
        {listItem.person && listItem.showHeadshot && (
          <Headshot person={listItem.person} />
        )}
        {listItem.showInitial && (
          <div className={styles.positionIcon} data-testid="listitem-initial">
            {listItem.name.slice(0, 1)}
          </div>
        )}

        <div className={styles.textContent} data-testid="listitem">
          <div>
            <div data-testid="titleText" className={styles.titleText}>
              {listItem.name}
              {listItem.warning && (
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className={styles.warning}
                />
              )}
            </div>
            {listItem.subtitle && (
              <div data-testid="subtitleText" className={styles.subtitleText}>
                {listItem.subtitle}
              </div>
            )}
            {withBadge && (
              <Badge text={withBadge.text} variant={withBadge.variant} />
            )}
          </div>
          {meetYourRep && (
            <div className={styles.expandRight}>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          )}
        </div>
      </div>
      {!!listItem.description && (
        <div
          className={classNames(styles.textContent, styles.listItemDescription)}
          data-testid="listItemDescription"
        >
          {listItem.description}
        </div>
      )}
    </>,
  )
}

export default ListItem
