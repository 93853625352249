import React, { useState } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { Candidate, Education, Experience } from 'types'
import {
  Badge,
  EndorsementList,
  LinkButton,
  Markdown,
  PartyInitials,
} from 'components/sparkles'
import DataPoints from './DataPoints/DataPoints'
import educationSorter from 'utils/educationSorter'
import experienceSorter from 'utils/experienceSorter'
import extractBlob from 'utils/extractBlob'
import HeadshotImage from 'assets/images/headshot.png'
import nameFormatter from 'utils/nameFormatter'
import './CandidateCard.scss'

interface Props {
  alert: any
  person: Candidate
  showCivicEngineBio: boolean
  showHeadshot: boolean
  showIncumbentTag: boolean
  showPartyInitials: boolean
  togglable: boolean
}

const CandidateCard: React.FC<Props> = ({
  alert,
  person,
  showCivicEngineBio,
  showHeadshot = true,
  showIncumbentTag = false,
  showPartyInitials = true,
  togglable = true,
}) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(p => !p)

  const name = nameFormatter(person)

  const bioBlob = extractBlob(person.markdowns, 'bio')
  const bioBottomBlob = extractBlob(person.markdowns, 'bio_bottom')
  const endorsementsBlob = extractBlob(person.markdowns, 'endorsements')
  const stancesBlob = extractBlob(person.markdowns, 'stances')

  const excludeUrlTypes = ['government'] // Remove these URL types
  const urls = person.urls.filter(u => !excludeUrlTypes.includes(u.type))

  return (
    <section className="CandidateCard">
      <header
        className={classNames('CandidateCard__header', {
          'CandidateCard__header--no-headshot': !showHeadshot,
        })}
      >
        {showHeadshot && (
          <img
            alt={name}
            className="CandidateCard__headshot"
            src={person.thumb_url || HeadshotImage}
          />
        )}
        <div>
          <h3>
            {name}
            {showPartyInitials && (
              <PartyInitials
                className="CandidateCard__PartyInitials"
                parties={person.parties}
              />
            )}
            {alert && (
              <FontAwesomeIcon
                className="CandidateCard__AlertIcon"
                icon={faExclamationTriangle}
              />
            )}
          </h3>
          {showIncumbentTag && person.incumbent && <Badge text="Incumbent" />}
          {person.badges.map(b => (
            <Badge text={b.title} key={b.id} />
          ))}
          {Array.isArray(person.urls) && (
            <div className="CandidateCard__urls">
              {urls.map((url, key) => (
                <LinkButton key={key} {...url} />
              ))}
            </div>
          )}
        </div>
        {togglable && (
          <button
            aria-label="Toggle"
            className="CandidateCard__toggle"
            onClick={toggleOpen}
          >
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </button>
        )}
      </header>

      {togglable && (
        <div
          className={classNames('CandidateCard__details', {
            'CandidateCard__details--open': open,
          })}
        >
          {showCivicEngineBio && person.bio_text && (
            <Markdown source={person.bio_text} />
          )}
          {bioBlob && <Markdown source={bioBlob} />}

          {showCivicEngineBio && (
            <DataPoints
              items={person.experience.slice().sort(experienceSorter)}
              title="Experience"
              formatter={(item: Experience) =>
                `${[item.company, item.position].filter(n => n).join(', ')} ${
                  item.start_year || item.end_year
                    ? `(${[item.start_year, item.end_year]
                        .filter(n => n)
                        .join(' - ')})`
                    : ''
                }`.trim()
              }
            />
          )}

          {showCivicEngineBio && (
            <DataPoints
              items={person.education.slice().sort(educationSorter)}
              title="Education"
              formatter={(item: Education) =>
                `${[item.degree, item.school, item.major]
                  .filter(n => n)
                  .join(', ')} ${
                  item.grad_year ? `(${item.grad_year})` : ''
                }`.trim()
              }
            />
          )}

          {stancesBlob && (
            <section className="CandidateCard__issues">
              <h4>Issues</h4>
              <Markdown
                className="CandidateCard__issues-blob"
                source={stancesBlob}
              />
            </section>
          )}

          {((person.endorsements && person.endorsements.length > 0) ||
            endorsementsBlob) && (
            <div>
              <h4>Endorsements</h4>
              <EndorsementList endorsements={person.endorsements} />
              {endorsementsBlob && (
                <Markdown
                  className="CandidateCard__issues-blob"
                  source={endorsementsBlob}
                />
              )}
            </div>
          )}

          {bioBottomBlob && <Markdown source={bioBottomBlob} />}
        </div>
      )}
    </section>
  )
}

export default CandidateCard
