import React, { ReactNode } from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import { TooltipArg } from 'react-popper-tooltip/dist/types'
import { Field, ErrorMessage, GenericFieldHTMLAttributes } from 'formik'
import classNames from 'classnames'
import { Markdown } from 'components/sparkles'
import styles from './InputFormGroup.module.scss'

interface FormGroupProps {
  errors?: string
  inputProps?: GenericFieldHTMLAttributes
  label: string
  name: string
  required: boolean
  tooltip?: string
  tooltipLabel?: string | ReactNode
  type?: string
}

const InputContext = React.createContext<string | null | undefined>(null)

const Tooltip: React.FC<TooltipArg> = ({
  getTooltipProps,
  getArrowProps,
  tooltipRef,
  arrowRef,
  placement,
}) => (
  <InputContext.Consumer>
    {value => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: classNames('tooltip-container', styles.tooltipContainer),
        })}
      >
        <Markdown source={value} />
        <div
          {...getArrowProps({
            ref: arrowRef,
            'data-placement': placement,
            className: 'tooltip-arrow',
          })}
        />
      </div>
    )}
  </InputContext.Consumer>
)

const InputFormGroup: React.FC<FormGroupProps> = ({
  errors,
  inputProps,
  label,
  name,
  required,
  tooltip,
  tooltipLabel,
  type,
}) => {
  const id = inputProps?.id || name

  return (
    <div className={styles.formGroup}>
      <div className={styles.labelContainer}>
        <label className={styles.label} htmlFor={id}>
          {label}
          {required && '*'}
        </label>

        {tooltip && (
          <InputContext.Provider value={tooltip}>
            <TooltipTrigger placement="auto" trigger="hover" tooltip={Tooltip}>
              {({ getTriggerProps, triggerRef }) => (
                <button
                  {...getTriggerProps({
                    ref: triggerRef,
                    className: styles.tooltipTrigger,
                  })}
                >
                  {tooltipLabel}
                </button>
              )}
            </TooltipTrigger>
          </InputContext.Provider>
        )}
      </div>

      <Field
        className={classNames(styles.input, {
          [styles.invalid]: errors,
        })}
        id={id}
        {...Object.assign({}, inputProps, { name, required, type })}
      />
      <ErrorMessage
        className={styles.errorMessage}
        component="div"
        name={name}
      />
    </div>
  )
}

export default InputFormGroup
