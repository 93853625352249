import fetchPositionCandidates from 'actions/fetchPositionCandidates'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { PositionCandidatesOptions, StoreState } from 'types'

export default (
  positionId: number,
): ThunkAction<void, StoreState, unknown, AnyAction> => async (
  dispatch,
  getState: () => StoreState,
) => {
  const {
    appConfig: {
      showAllEndorsements,
      showUncertifiedCandidates,
      showVotingHistory,
    },
  } = getState()

  const options: PositionCandidatesOptions = {}

  if (showAllEndorsements) {
    options.include_all_endorsements = '1'
  }

  if (showUncertifiedCandidates) {
    options.include_uncertified_candidates = '1'
  }

  if (showVotingHistory) {
    options.check_or_x = '1'
  }

  return dispatch(fetchPositionCandidates(positionId, options))
}
