import { AppConfig, AppIdentifier } from 'appConfig'
import appConfig from '../appConfig.json'
import usStates from '../data/usStates.json'

//run without passed in AppConfig so we don't have to get AppConfig everywhere this is called
export default (app: AppIdentifier) =>
  getPathWithConfig(appConfig as AppConfig, app)

//add option to test with passed in AppConfig
export const getPathWithConfig = (config: AppConfig, app: AppIdentifier) => {
  switch (app) {
    case 'check_registration':
      return '/check_registration'
    case 'dashboard':
      return '/dashboard/'
    case 'election_center':
      if (config.landingPage === 'election_center') {
        return '/'
      } else {
        return '/ec/'
      }
    case 'explore_by_map':
      return '/map/'
    case 'home':
      return '/'
    case 'maptv':
      return '/m/'
    case 'meet_your_rep':
      return '/meet_your_rep'
    case 'no_elections':
      return '/no_elections'
    case 'office_holders':
      return '/office_holders'
    case 'pledge':
      return '/pledge/'
    case 'recruitment':
      return '/run'
    case 'voter_registration':
      return '/register'
    case 'request_ballot':
      return '/request_ballot/'
    case 'sandbox':
      return '/sandbox/'
    case 'search':
      return '/search/'
    case 'seo_us_states':
      return `/(${usStates.map(s => s.short_name).join('|')})(.*)/`
    case 'shareable_ballot':
      return '/sb/'
    case 'super_slate':
      if (config.landingPage === 'super_slate') {
        return '/'
      } else {
        //trailing / so that /candidates/:id pages show correctly
        return '/ssc/'
      }
    case 'user':
      return '/u/'
    case 'voter_guide':
      return '/v/'
    case 'widget':
      return '/w/'
    default:
      return '/'
  }
}
