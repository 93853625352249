import Cookies from 'js-cookie'
import qs from 'qs'

import saveBallotParams from 'actions/saveBallotParams'

import pingBallot from './pingBallot'

export default (values, history, redirect = true, analytics) => async (
  dispatch,
  getState,
) => {
  const ballotApiUrl = process.env.REACT_APP_BALLOT_API_URL
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  let utmParams = {}
  if (history && history.location) {
    const {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
    } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    })

    utmParams = {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
    }
  }

  const {
    appConfig: {
      parties: { forcePartyId },
      tenant,
    },
    auth: {
      user: { ballot_id, ballot_user_id, engine_token },
    },
  } = getState()

  const auth = ballot_id && engine_token && btoa(`${ballot_id}:${engine_token}`)
  if (auth) {
    headers['Authorization'] = `Basic ${auth}`
  }

  const ballot = Object.assign(
    {},
    { ...values },
    { ...utmParams },
    {
      ballot_user_id: ballot_user_id, // associate with signed in user
      party_id: forcePartyId,
      tenant_id: tenant.id,
    },
  )

  const response = await fetch(`${ballotApiUrl}/ballots`, {
    method: 'POST',
    body: JSON.stringify({ ballot }),
    headers,
  })

  if (response && response.ok) {
    const { ballot_user_id, engine_token, id } = await response.json()

    const uid = ballot_user_id ? ballot_user_id : null

    // Store bid and et in cookie for later
    Cookies.set(
      'ce_s',
      {
        bid: id,
        et: engine_token,
        uid,
      },
      {
        expires: 30,
      },
    )
    dispatch(saveBallotParams(id, engine_token, uid))
    dispatch(pingBallot(history, redirect, undefined, analytics))
  }
}
