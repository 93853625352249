import { Button, Markdown } from '../../atoms'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface TextReadMoreProps {
  className?: string
  cutoff?: number
  text: string
}

const TextReadMore: React.FC<TextReadMoreProps> = ({
  className,
  cutoff = 200,
  text,
}) => {
  const [showFullText, setShowFullText] = useState(false)
  const { t } = useTranslation()

  const useTruncation = text?.length > cutoff

  function getTruncatedText() {
    let newLength = cutoff
    while (
      text[newLength] !== '.' &&
      text[newLength] !== '?' &&
      text[newLength] !== '!'
    ) {
      newLength++
    }

    return text.slice(0, newLength + 1)
  }

  if (!text) {
    return null
  }

  return (
    <div data-cy="textreadmore">
      <Markdown className={className}>
        {showFullText || !useTruncation ? text : getTruncatedText()}
      </Markdown>
      {useTruncation && (
        <Button
          data-cy="textreadmore-toggle"
          variant="link"
          onClick={() => setShowFullText(!showFullText)}
        >
          {showFullText
            ? t('sparkles.main:TextReadMore.readLess')
            : t('sparkles.main:TextReadMore.readMore')}
        </Button>
      )}
    </div>
  )
}

export default TextReadMore
