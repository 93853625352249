import React from 'react'
import classNames from 'classnames'
import { Person } from 'types'

import HeadshotImage from './headshot.png'

import styles from './Headshot.module.scss'
import { useTranslation } from 'react-i18next'

// todo - for position, just show first letter of position name

export interface HeadshotProps {
  active?: boolean
  alt?: string
  border?: boolean
  className?: string
  imageUrl?: string
  person?: Person
  responsive?: boolean
  shadow?: boolean
  showHeadshot?: boolean
  size?: 'xs' | 'lg'
}

const Headshot: React.FC<HeadshotProps> = ({
  active,
  alt,
  border = false,
  className,
  imageUrl,
  person,
  responsive = true,
  shadow = true,
  showHeadshot = true,
  size,
}) => {
  const { t } = useTranslation()
  return (
    <img
      alt={
        alt ||
        ((imageUrl || (person && person.thumb_url)) &&
          t('sparkles.main:Headshot.Headshot')) ||
        t('sparkles.main:Headshot.Placeholder')
      }
      data-cy="headshot"
      data-testid="headshot"
      className={classNames(
        styles.Headshot,
        active && styles.active,
        border && styles.border,
        responsive && styles.responsive,
        shadow && styles.shadow,
        size && styles[size],
        person?.withdrawn && styles.withdrawn,
        className,
      )}
      src={
        (showHeadshot && (imageUrl || (person && person.thumb_url))) ||
        HeadshotImage
      }
    />
  )
}

export default Headshot
