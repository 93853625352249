import React from 'react'
import classNames from 'classnames'
import { CandidateUrl, Person } from 'types'
import Headshot from '../../atoms/Headshot'

import Notification from '../../atoms/Notification'
import styles from './candidateHeader.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-light-svg-icons'
import nameFormatter from 'utils/nameFormatter'
import CandidateUrls from '../../molecules/CandidateUrls'
import { useTranslation } from 'react-i18next'

export type HeaderProps<T extends PersonHeader> = {
  badge?: string
  children?: React.ReactNode
  className?: string
  featured?: boolean
  notificationText?: string
  showHeadshot?: boolean
  showPartyInitials?: boolean
  showUrls?: boolean
  person: T
  shouldShowParty?: boolean
}

export interface PersonHeader extends Person {
  urls: CandidateUrl[]
  isCandidate: boolean
  party_name?: string
  running_mate?: Person
  position_name: string
  name: string
}

const CandidateHeader = <T extends PersonHeader>({
  badge = 'Recommended',
  children,
  className,
  featured = false,
  notificationText,
  person,
  showHeadshot = true,
  showPartyInitials = false,
  showUrls = true,
  shouldShowParty = true,
}: HeaderProps<T>) => {
  const { t } = useTranslation()

  return (
    <header
      className={classNames(styles.candidateHeader, className)}
      data-cy="candidateheader"
      data-testid={person.name}
    >
      {person.withdrawn && (
        <Notification variant="info" size="sm" className={styles.notification}>
          {notificationText}
        </Notification>
      )}
      <div className={styles.headshot}>
        <Headshot
          person={person}
          responsive
          showHeadshot={showHeadshot}
          size="lg"
        />{' '}
      </div>

      <h1>{person.name}</h1>
      {showPartyInitials && shouldShowParty && !!person.party_name && (
        <h2>{person.party_name}</h2>
      )}
      {person.isCandidate ? (
        <h2>
          {t('sparkles.main:CandidateHeader.formattedPositionName', {
            position: person.position_name,
          })}
        </h2>
      ) : (
        <h2>{person.position_name}</h2>
      )}

      {person.running_mate && (
        <p className={styles.runningMate}>
          Running Mate: {nameFormatter(person.running_mate)}
        </p>
      )}
      {showUrls && <CandidateUrls className={styles.urls} urls={person.urls} />}

      {featured && (
        <div className={styles.featured}>
          <FontAwesomeIcon icon={faStar} />
          {badge}
        </div>
      )}
      {children}
    </header>
  )
}

export default CandidateHeader
