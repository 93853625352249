import React from 'react'
import { Ballot } from 'types'
import styles from './AddressBoxInner.module.scss'
import { enterKeyPress } from 'utils/enterKeyPress'
import { twoLineAddress } from 'utils/twoLineAddress'
import classNames from 'classnames'

export interface Props {
  ballot: Ballot
  changeAddressLinkText?: string
  heading: string
  onClick(event: React.MouseEvent | React.KeyboardEvent): void
  withBackground: boolean
}

const AddressBoxInner: React.FC<Props> = ({
  ballot,
  changeAddressLinkText,
  heading,
  onClick,
  withBackground = false,
}) => {
  const address = twoLineAddress(ballot)

  return (
    <div
      data-testid="AddressBox"
      onClick={onClick}
      onKeyPress={(e: React.KeyboardEvent) => {
        if (enterKeyPress(e)) {
          onClick(e)
        }
      }}
      role="button"
      tabIndex={0}
      className={classNames(
        withBackground ? styles.containerWithBackground : styles.container,
      )}
    >
      <div
        className={classNames(
          withBackground ? styles.withBackground : styles.addressBox,
        )}
      >
        <span>{heading}</span>
        <p>{address.addressLine1}</p>
        <p>{address.addressLine2}</p>
      </div>
      <p
        className={styles.addressLink}
        onClick={onClick}
        onKeyPress={(e: React.KeyboardEvent) => {
          if (enterKeyPress(e)) {
            onClick(e)
          }
        }}
        role="link"
        tabIndex={0}
      >
        {changeAddressLinkText}
      </p>
    </div>
  )
}

export default AddressBoxInner
