import React from 'react'
import { Link } from 'react-router-dom'
import { Headshot } from 'components/sparkles'
import nameFormatter from 'utils/nameFormatter'
import './OfficeCard.scss'
import { Position } from 'types'

interface Props {
  position: Position
  state?: string
}

const OfficeCard: React.FC<Props> = ({ position, state }) => {
  if (position?.candidates?.length === 0) {
    return null
  }

  return (
    <div className="OfficeCard">
      <h4>
        {position.sub_area_name && position.sub_area_value
          ? `${position.sub_area_name} ${position.sub_area_value}`
          : position.name}
      </h4>

      <ul className="OfficeCard__CandidateList">
        {position?.candidates?.map(candidate => (
          <li key={candidate.id}>
            <Headshot person={candidate} />
            <header>
              <Link
                to={`/${state}/${position.slug ||
                  position.position_id}/${candidate.slug || candidate.id}`}
              >
                {nameFormatter(candidate)}
              </Link>{' '}
              ({candidate.party_name})
            </header>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default OfficeCard
