import React, { useRef } from 'react'
import { FieldProps } from 'formik'
import ReactSignatureCanvas from 'react-signature-canvas'
import styles from './SignatureField.module.css'
import classNames from 'classnames'
import { Button } from 'components/sparkles'
import { useTranslation } from 'react-i18next'

interface OwnProps {
  className: string
  error: boolean
  onEndSignature?(value: string | undefined): void
}

const SignatureField = ({
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  onEndSignature,
  ...props
}: FieldProps & OwnProps) => {
  const signatureRef = useRef<ReactSignatureCanvas>(null)
  const { t } = useTranslation()
  const { className, error } = props

  return (
    <div className={styles.signatureContainer}>
      <Button
        className={styles.clear}
        onClick={() => signatureRef?.current?.clear()}
        size="sm"
        variant="default"
      >
        {t('sparkles:SignatureField.clear')}
      </Button>
      <ReactSignatureCanvas
        canvasProps={{
          className: classNames(className, styles.signaturePad, {
            [styles.signaturePad__error]: error,
          }),
          height: window.innerWidth > 576 ? '200px' : '100px',
        }}
        ref={signatureRef}
        onEnd={() => {
          const signatureValue = signatureRef?.current?.toDataURL()
          setFieldValue('signature', signatureValue)
          onEndSignature && onEndSignature(signatureValue)
        }}
      />
    </div>
  )
}
export default SignatureField
