import {
  FETCH,
  FETCH_ERROR,
  FETCH_RECEIVE,
  FETCH_REQUEST,
} from 'actions/constants'
import { ApiResponse } from 'types'

interface FetchError {
  status: number
  statusText: string
}

interface FetchOptions {
  method: 'POST' | 'PATCH' | 'GET' | 'DELETE'
  body: any
}

class FetchResponse<T> extends ApiResponse<T> {
  type!: string
  path!: string
  options?: FetchOptions | {}
}

export const apiFetchError = ({ status, statusText }: FetchError) => ({
  type: FETCH_ERROR,
  status,
  statusText,
})

export const apiFetchReceive = () => ({ type: FETCH_RECEIVE })
export const apiFetchRequest = () => ({ type: FETCH_REQUEST })

export default (
  path: string,
  options: FetchOptions | {} = {},
): FetchResponse<any> => {
  const response = new FetchResponse()
  response.type = FETCH
  response.path = path
  response.options = options
  return response
}
