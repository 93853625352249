import React from 'react'
import classNames from 'classnames'
import styles from './badge.module.scss'

interface BadgeProps {
  text: string
  variant?: string
}

const Badge: React.FC<BadgeProps> = ({ text, variant }) => {
  return (
    <span className={classNames(styles.badge, variant && styles[`${variant}`])}>
      {text}
    </span>
  )
}

export default Badge
