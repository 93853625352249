import React, { useState } from 'react'
import appBoilerplateContainer from './appBoilerplateContainer'

import styles from './AppBoilerplate.module.scss'
import LocalNav from 'components/LocalNav/LocalNav'
import { Ballot } from 'types'
import { useTranslation } from 'react-i18next'
import { Footer } from 'components/sparkles'

interface Props {
  ballot: Ballot
  children: React.ReactNode
  defaultElectionId: number | null
  disclaimer: string | null
  dismissGlobalAlert?(): void
  hideNavOnSmallScreens?: boolean
  homePath: string
  isSmallScreen: boolean
  showGlobalAlert: boolean
  shouldShowAddress?: boolean
  shouldShowAlert?: boolean
  shouldShowFooter?: boolean
  supportPortalUrl: string
  testId?: string
}

const AppBoilerplate: React.FC<Props> = ({
  ballot,
  children,
  defaultElectionId,
  dismissGlobalAlert,
  disclaimer,
  hideNavOnSmallScreens,
  homePath,
  isSmallScreen,
  showGlobalAlert,
  shouldShowAddress = false,
  shouldShowAlert,
  shouldShowFooter = true,
  supportPortalUrl,
  testId,
}) => {
  const { i18n, t } = useTranslation()
  const electionAlertKey = `alerts:Election.${ballot?.election_id ||
    defaultElectionId}`

  const electionAlertExists =
    i18n.exists && i18n.exists(electionAlertKey) && t(electionAlertKey)
  const electionAlert = electionAlertExists ? t(electionAlertKey) : null

  const [showAlert, setShowAlert] = useState(
    !!(electionAlert && showGlobalAlert),
  )
  return (
    <div className={styles.root} data-testid={testId}>
      {!(hideNavOnSmallScreens && isSmallScreen) && (
        <LocalNav
          addressUpdateNeedsBallot={false}
          alert={shouldShowAlert && showAlert ? electionAlert : null}
          homePath={homePath}
          onAlertClose={() => {
            dismissGlobalAlert && dismissGlobalAlert()
            setShowAlert(false)
          }}
          showAddress={shouldShowAddress}
        />
      )}
      {children}
      {shouldShowFooter && (
        <Footer disclaimer={disclaimer} supportPortalUrl={supportPortalUrl} />
      )}
    </div>
  )
}

export default appBoilerplateContainer(AppBoilerplate)
