import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import Select, { ActionMeta, ValueType } from 'react-select'
import { Endorsement, Stance } from 'types'
import { IssueCategory, IssueCategoryItem } from 'types'
import { SourcedSnippet } from '../../../atoms'
import { isStance } from 'utils/stanceAndEndorsementUtils'
import styles from './issueSortedItemsList.module.scss'

export interface IssueSortedListItemProps {
  items: IssueCategory[]
  onSelectIssue(selectedIssue: IssueCategory): void
  placeholder: string // required for accessibility
  selectedIssue?: IssueCategory
  showQuotationMarks?: boolean
  title: string // required for accessibility
}

type IssueOption = { label: string; value: number }

const IssueSortedItemsList: React.FC<IssueSortedListItemProps> = ({
  items,
  onSelectIssue,
  placeholder,
  selectedIssue,
  showQuotationMarks,
  title,
}) => {
  const { i18n, t } = useTranslation()

  const listedItems = selectedIssue && selectedIssue.items

  let useLocale: string | undefined = i18n.language

  if (listedItems && i18n.languages?.length > 1) {
    // Determine the locale for this issue. We'll only ever show issues in 1
    // language. Which language this is is determined by finding the most
    // specific locale that has a stance in the issue's stances.
    useLocale = i18n.languages?.find(l =>
      listedItems.findIndex(
        (s: Stance | Endorsement) => (s as Stance).locale === l,
      ) === -1
        ? null
        : l,
    )
  }

  const selectIssue = (issue: IssueCategory) => {
    onSelectIssue(issue)
  }

  const selectIssueById = (
    issueOption: ValueType<IssueOption>,
    action: ActionMeta<IssueOption>,
  ) => {
    if (action.action === 'select-option') {
      const issue =
        issueOption &&
        !Array.isArray(issueOption) &&
        items.find(i => i.id === (issueOption as IssueOption).value)
      if (issue) {
        selectIssue(issue)
      }
    }
  }

  const convertToOption = (issue: IssueCategory) => {
    return {
      label: issue.key
        ? t(`sparkles.main:Issues.${issue.key}.name`, issue.name)
        : issue.name,
      value: issue.id,
    }
  }
  return (
    <div className={styles.container} data-testid="issue-list">
      <label
        id={`aria-label-${title}`}
        htmlFor="issue-select"
        aria-label={placeholder}
      >
        <Select
          aria-labelledby={`aria-label-${title}`}
          inputId={`issue-select-${title}`}
          className={styles.select}
          menuPlacement="auto"
          isMulti={false}
          inputProps={{ readOnly: true }}
          isSearchable={false}
          onChange={selectIssueById}
          options={items.map((item: IssueCategory) => convertToOption(item))}
          placeholder={placeholder}
          value={selectedIssue && convertToOption(selectedIssue)}
        />
      </label>
      <ul className={styles.root}>
        {items.map((issue, key) => {
          const issueTitle = issue.key
            ? t(`sparkles.main:Issues.${issue.key}.name`, issue.name)
            : issue.name
          return (
            <li key={key}>
              <button
                className={classNames(
                  styles.IssueList__Issue,
                  selectedIssue &&
                    selectedIssue.id === issue.id &&
                    styles.IssueList__Issue__active,
                  issueTitle.length > 16 && styles.IssueList__Issue__long,
                )}
                data-cy="issuesorteditemslist-item"
                onClick={() => selectIssue(issue)}
              >
                {issueTitle}
              </button>
            </li>
          )
        })}
      </ul>
      {selectedIssue && (
        <article className={styles.IssueList__Stances}>
          <h3>
            {selectedIssue.key
              ? t(`issues:${selectedIssue.key}.name`, selectedIssue.name)
              : selectedIssue.name}
          </h3>

          {selectedIssue?.question_text && (
            <strong>
              {selectedIssue.key
                ? t(
                    `sparkles.main:issues${selectedIssue.key}.question`,
                    selectedIssue.question_text,
                  )
                : selectedIssue.question_text}
            </strong>
          )}
          {listedItems &&
            listedItems
              .filter((s: Stance | Endorsement) => {
                if (isStance(s)) {
                  return s.locale === useLocale
                }
                return true
              })
              .map((item: IssueCategoryItem, index: number) => {
                let text
                if (isStance(item)) {
                  text = showQuotationMarks
                    ? `"${item.description}"`
                    : item.description
                } else {
                  text = item.name
                }
                const sourceUrl = isStance(item)
                  ? item.reference_url
                  : item.website_url
                return (
                  <SourcedSnippet
                    className=""
                    key={index}
                    snippet={text}
                    sourceUrl={sourceUrl}
                  />
                )
              })}
        </article>
      )}
    </div>
  )
}

export default IssueSortedItemsList
