import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import { faBriefcase } from '@fortawesome/pro-regular-svg-icons/faBriefcase'
import { faGraduationCap } from '@fortawesome/pro-light-svg-icons'
import { Candidate, Education, Experience } from 'types'
import extractBlob from 'utils/extractBlob'
import experienceSorter from 'utils/experienceSorter'
import educationSorter from 'utils/educationSorter'
import { DataPoints, Markdown } from '../../atoms'
import styles from './bio.module.scss'

export interface BioProps {
  candidate: Candidate
  showCivicEngineBio?: boolean
}

const Bio: React.FC<BioProps> = ({ candidate, showCivicEngineBio = true }) => {
  const { i18n, t } = useTranslation()
  const videoUrl = extractBlob(candidate.markdowns, 'video', i18n.language)
  const bioBlob = extractBlob(candidate.markdowns, 'bio', i18n.language)
  const bioBottomBlob = extractBlob(
    candidate.markdowns,
    'bio_bottom',
    i18n.language,
  )
  const website = candidate.urls.find(e => e.type === 'website')
  const websiteUrl = website && website.url

  const experience = candidate.experience
    .slice() // don't want to mutate original array
    .sort(experienceSorter)
    .map(e => ({ ...e, source_url: websiteUrl })) // Add website as source

  const education = candidate.education
    .slice() // don't want to mutate original array
    .sort(educationSorter)
    .map(e => ({ ...e, source_url: websiteUrl })) // Add website as source

  // if the candidate does not have any info, don't show this component
  if (
    education.length === 0 &&
    experience.length === 0 &&
    !videoUrl &&
    !bioBlob &&
    !bioBottomBlob
  ) {
    return null
  }
  return (
    <section data-testid="bio-section" className={styles.bio}>
      <h2>{t('sparkles.main:CandidateDetails.Bio.title')}</h2>
      {videoUrl && (
        <div className={styles.videoWrapper} data-testid="video-player">
          <ReactPlayer
            className={styles.video}
            controls
            height="100%"
            url={videoUrl}
            width="100%"
          />
        </div>
      )}
      {bioBlob && (
        <div data-testid="bio-top">
          <Markdown className={styles.blob} source={bioBlob} />
        </div>
      )}
      {showCivicEngineBio && (
        <DataPoints
          formatter={(item: Experience) =>
            `${[item.company, item.position].filter(n => n).join(', ')} ${
              item.start_year || item.end_year
                ? `(${[item.start_year, item.end_year]
                    .filter(n => n)
                    .join(' - ')})`
                : ''
            }`.trim()
          }
          icon={faBriefcase}
          items={experience}
          title={t('sparkles.main:CandidateDetails.Bio.experience')}
        />
      )}
      {showCivicEngineBio && (
        <DataPoints
          formatter={(item: Education) =>
            `${[item.degree, item.school, item.major]
              .filter(n => n)
              .join(', ')} ${
              item.grad_year ? `(${item.grad_year})` : ''
            }`.trim()
          }
          icon={faGraduationCap}
          items={education}
          title={t('sparkles.main:CandidateDetails.Bio.education')}
        />
      )}

      {bioBottomBlob && (
        <div data-testid="bio-bottom">
          <Markdown className={styles.blob} source={bioBottomBlob} />
        </div>
      )}
    </section>
  )
}

export default Bio
