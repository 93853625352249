import React, { ReactElement, useEffect } from 'react'
import { useLocation } from 'react-router'

interface Props {
  children: ReactElement | ReactElement[]
}

const ScrollToTop: React.FC<Props> = ({ children }: Props) => {
  let location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return <>{children}</>
}

export default ScrollToTop
