import './polyfills'
import 'react-popper-tooltip/dist/styles.css'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { hydrate, render } from 'react-dom'
import qs from 'qs'
import MockDate from 'mockdate'
import './iframeResizerSetup'
import 'iframe-resizer/js/iframeResizer.contentWindow'
import './i18n.js'
import './index.scss'
import { debug, allowTimeTravel } from './appConfig.json'
import App from './App'
import * as serviceWorker from './serviceWorker'
import UnsupportedBrowserApp from './UnsupportedBrowserApp'

Sentry.init({
  dsn: process.env.REACT_APP_RAVEN_DSN,
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.NODE_ENV,
  normalizeDepth: 10, // For redux state,
})

/**
 * Accessibility linting
 * TODO(eth): tie this to some other environment variable so that axe won't be included in non-test production builds
 */
if (debug) {
  const axe = require('@axe-core/react')

  const context = {
    exclude: [['#DebugPanel']],
  }

  axe(React, ReactDOM, 1000, undefined, context)
}

/**
 * Time travel debugging
 */
if (debug || allowTimeTravel) {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

  if (params.at) {
    MockDate.set(new Date(params.at))
  }
}

let isIE = false

/**
 * This check should catch all IE versions <= 10
 *
 * @see https://stackoverflow.com/questions/19638981/window-activexobject-difference-in-ie11#20244319
 */

if ((window as any).ActiveXObject) {
  isIE = true
}

const rootElement = document.getElementById('root')

if (isIE) {
  render(<UnsupportedBrowserApp />, rootElement)
} else {
  if (rootElement && rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement)
  } else {
    render(<App />, rootElement)
  }

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister()
}
