import * as types from 'actions/constants'

const candidates = (
  state = {
    didInvalidate: false,
    isFetching: false,
    items: [],
    lastUpdated: null,
  },
  action,
) => {
  switch (action.type) {
    case types.INVALIDATE_POSITION_CANDIDATES:
      return Object.assign({}, state, {
        didInvalidate: true,
      })
    case types.REQUEST_POSITION_CANDIDATES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      })
    case types.RECEIVE_POSITION_CANDIDATES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.payload,
        lastUpdated: action.receivedAt,
      })
    default:
      return state
  }
}

const candidatesByPosition = (state = {}, action) => {
  switch (action.type) {
    case types.INVALIDATE_POSITION_CANDIDATES:
    case types.REQUEST_POSITION_CANDIDATES:
    case types.RECEIVE_POSITION_CANDIDATES:
      return Object.assign({}, state, {
        [action.positionId]: candidates(state[action.positionId], action),
      })
    case types.RESET_ALL_POSITION_CANDIDATES:
      return {}
    default:
      return state
  }
}

export default candidatesByPosition
