import React, { useEffect, useRef, useState } from 'react'
import { Candidate, Position, Selection } from 'types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons'
import throttle from 'lodash/throttle'

import { CandidacyListItem } from '../../molecules'
import styles from './candidateList.module.scss'

export interface FeaturedCandidate extends Candidate {
  featured?: boolean
}
export interface CandidateListProps {
  activeCandidateId: number | null
  candidates?: FeaturedCandidate[]
  electionId?: number | null
  featuredBadge?: string
  hasBadge?: boolean
  inDirectory?: boolean
  linkTarget?(candidate: Candidate): string
  onCandidateClick?(event: React.MouseEvent | React.KeyboardEvent): void
  position: Position
  selections?: Selection[]
  shouldRedirect: boolean
}

const CandidateList: React.FC<CandidateListProps> = ({
  activeCandidateId,
  candidates,
  electionId,
  featuredBadge,
  hasBadge = false,
  inDirectory = false,
  linkTarget,
  onCandidateClick,
  position,
  selections,
  shouldRedirect,
}) => {
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollLeftMax, setScrollLeftMax] = useState(0)

  const scrollContainerRef = useRef<HTMLElement | null>(null)

  const scrollTo = (direction: 'left' | 'right') => {
    const container = scrollContainerRef?.current

    if (!container) {
      return
    }

    const currentScroll = container?.scrollLeft
    let scrollSize = container?.clientWidth || 0

    if (direction === 'left') {
      scrollSize = -scrollSize
    }

    container.scrollLeft = currentScroll + scrollSize
  }

  useEffect(() => {
    const container = scrollContainerRef.current
    if (!container) {
      return
    }

    const newScrollLeftMax = container.scrollWidth - container.clientWidth

    if (scrollLeftMax !== newScrollLeftMax) {
      setScrollLeftMax(newScrollLeftMax)
    }
  }, [candidates, scrollLeftMax])

  const handleScroll = () => {
    const container = scrollContainerRef.current

    if (!container) {
      return
    }
    setScrollLeft(container.scrollLeft)
  }

  const handleScrollThrottled = throttle(handleScroll, 1000)

  return (
    <div className={styles.container_outer} data-cy="candidatelist">
      <nav
        className={classNames(
          styles.nav,
          scrollLeft === 0 && styles.right_only,
        )}
      >
        <button
          className={classNames(
            styles.navBtn,
            styles.leftNav,
            scrollLeft === 0 && styles.hidden,
          )}
          data-cy="candidatelist-left"
          onClick={() => scrollTo('left')}
          title="left"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <button
          className={classNames(
            styles.navBtn,
            styles.rightNav,
            scrollLeft >= scrollLeftMax && styles.hidden,
          )}
          data-cy="candidatelist-right"
          onClick={() => scrollTo('right')}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </nav>
      <section
        className={classNames(
          styles.candidateListContainer,
          hasBadge && styles.hasBadge,
        )}
        onScroll={handleScrollThrottled}
        ref={scrollContainerRef}
      >
        <ul className={styles.candidateList}>
          {candidates?.map((candidate: FeaturedCandidate) => {
            const candidateId = candidate.id || candidate.candidate_id
            const select = selections?.find(
              selection =>
                selection.candidate_id === candidate.id ||
                selection.candidate_id === candidate.candidate_id,
            )
            return (
              <li
                className={styles.item}
                data-cy="candidatelist-li"
                key={candidateId}
              >
                <CandidacyListItem
                  active={
                    !!(activeCandidateId && activeCandidateId === candidateId)
                  }
                  badge={featuredBadge}
                  candidate={candidate}
                  electionId={electionId}
                  featured={candidate.featured}
                  inDirectory={inDirectory}
                  key={candidateId}
                  linkTarget={linkTarget && linkTarget(candidate)}
                  onClick={onCandidateClick}
                  position={position}
                  selection={select}
                  shouldRedirect={shouldRedirect}
                  small={false}
                />
              </li>
            )
          })}
        </ul>
      </section>
    </div>
  )
}

export default CandidateList
