import React, { useState } from 'react'
import addressModalControllerContainer from './addressModalControllerContainer'
import { Ballot } from 'types'
import AddressModal from './AddressModal/AddressModal'

interface Props {
  ballot: Ballot
  children(props: {
    onClick(e: React.MouseEvent | React.KeyboardEvent): void
    'aria-haspopup': boolean
    'aria-controls': string
  }): React.ReactNode
  shouldRedirect?: boolean
  needsBallot?: boolean
  onUpdateAddress?: () => void
  modalTitle?: string
}

const AddressModalController: React.FC<Props> = ({
  ballot,
  children,
  needsBallot = true,
  shouldRedirect = true,
  onUpdateAddress,
  modalTitle,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!ballot && needsBallot) {
    return null
  }

  return (
    <>
      {children({
        onClick: (e: React.MouseEvent) => {
          e.preventDefault()
          setIsModalOpen(true)
        },
        'aria-haspopup': true,
        'aria-controls': 'address-modal',
      })}

      {isModalOpen && (
        <AddressModal
          ballot={ballot}
          onClose={() => setIsModalOpen(false)}
          onUpdateAddress={onUpdateAddress}
          shouldRedirect={shouldRedirect}
          modalTitle={modalTitle}
        />
      )}
    </>
  )
}

export default addressModalControllerContainer(AddressModalController)
