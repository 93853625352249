import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Fetch from 'i18next-fetch-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import resources from './locales'
import { debug, supportedLocales } from './appConfig'

const shouldFetchI18nRemote = !debug

// merge resources
const allResources = shouldFetchI18nRemote ? null : resources

let backendOptions = {}
if (shouldFetchI18nRemote) {
  backendOptions = {
    loadPath: (lng, namespace) => {
      const package_source = 'civic-engines'
      return `https://i18n-cdn.civicengine.com/${package_source}/${process.env.REACT_APP_BRANCH}/${lng}/${namespace}.json`
    },
  }

  // Add Fetch backend
  i18n.use(Fetch)
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      backend: backendOptions,
      detection: {
        order: [
          'querystring',
          'cookie',
          'localStorage',
          'navigator',
          'htmlTag',
          'path',
          'subdomain',
        ],
        // order: ['querystring', 'htmlTag'],
      },
      fallbackLng: 'en',

      // Only in development because even in test it's annoying to have all this
      // debug output
      debug: process.env.NODE_ENV === 'development',

      // Other namespaces are lazy-loaded in their respective *App component
      ns: ['alerts', 'common', 'sparkles', 'sparkles.main'],
      defaultNS: 'common',

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      parseMissingKeyHandler:
        process.env.NODE_ENV === 'production' && (() => null),
      resources: allResources,
    },
    () => {
      // Force changing the language to custom locale if configured
      if (!supportedLocales.includes(i18n.language)) {
        i18n.changeLanguage(supportedLocales[0])
      }
    },
  )

export default i18n
